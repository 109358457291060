import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import OpenAI from 'openai';
import axios from 'axios';
import md5 from 'md5';
import './App.css';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
    <p>Trwa wycenianie...</p>
  </div>
);

function App() {
  const [projectDescription, setProjectDescription] = useState('');
  const [technicalRequirements, setTechnicalRequirements] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [includeEditing, setIncludeEditing] = useState(false);
  const [includeGraphics, setIncludeGraphics] = useState(false);
  const [includeColorGrading, setIncludeColorGrading] = useState(false);
  const [estimatedFinalDuration, setEstimatedFinalDuration] = useState('');
  const [rates, setRates] = useState(null);
  const [crewCategories, setCrewCategories] = useState([]);
  const [qualityLevel, setQualityLevel] = useState('basic');
  const [shootingDays, setShootingDays] = useState('');
  const [error, setError] = useState(null);

  const openaiRef = useRef(null);
  const cache = useMemo(() => new Map(), []);

  useEffect(() => {
    if (!process.env.REACT_APP_OPENAI_API_KEY) {
      setError('OpenAI API key is not set');
      return;
    }
    openaiRef.current = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });

    async function fetchRates() {
      try {
        const sheetId = process.env.REACT_APP_GOOGLE_SHEET_ID;
        const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:json`;
        
        const response = await axios.get(url);
        const data = JSON.parse(response.data.substr(47).slice(0, -2));
        
        const ratesFromSheet = {};
        const categoriesFromSheet = [];
        data.table.rows.forEach(row => {
          const [key, minValue, maxValue] = row.c;
          if (key && minValue && maxValue) {
            const position = key.v.toLowerCase();
            ratesFromSheet[position] = {
              min: parseFloat(minValue.v),
              max: parseFloat(maxValue.v)
            };
            categoriesFromSheet.push(position);
          }
        });

        setRates(ratesFromSheet);
        setCrewCategories(categoriesFromSheet);
      } catch (error) {
        console.error('Error loading rates:', error);
        setError('Wystąpił błąd podczas ładowania stawek. Spróbuj odświeżyć stronę.');
      }
    }

    fetchRates();
  }, []);

  const calculateCosts = useCallback((analysisResult, rates, qualityLevel, includeGraphics, includeColorGrading, shootingDays, finalDuration) => {
    let totalCost = 0;
    const detailedCrew = [];
    if (Array.isArray(analysisResult.suggestedCrew)) {
      analysisResult.suggestedCrew.forEach(crewMember => {
        const rateRange = rates[crewMember.role.toLowerCase()];
        if (rateRange) {
          let rate;
          switch (qualityLevel) {
            case 'modest':
              rate = rateRange.min;
              break;
            case 'basic':
              rate = (rateRange.min + rateRange.max) / 2;
              break;
            case 'premium':
              rate = rateRange.max;
              break;
            default:
              rate = (rateRange.min + rateRange.max) / 2;
          }
          const crewMemberCost = rate * crewMember.daysNeeded;
          totalCost += crewMemberCost;
          detailedCrew.push({
            ...crewMember,
            cost: Math.ceil(crewMemberCost)
          });
        }
      });

      // Dodaj koszt studia
      const studioRate = rates['studio'];
      if (studioRate) {
        let studioRateForQuality;
        switch (qualityLevel) {
          case 'modest':
            studioRateForQuality = studioRate.min;
            break;
          case 'basic':
            studioRateForQuality = (studioRate.min + studioRate.max) / 2;
            break;
          case 'premium':
            studioRateForQuality = studioRate.max;
            break;
          default:
            studioRateForQuality = (studioRate.min + studioRate.max) / 2;
        }
        const studioCost = studioRateForQuality * shootingDays;
        totalCost += studioCost;
        detailedCrew.push({ role: 'Studio', daysNeeded: shootingDays, cost: Math.ceil(studioCost) });
      }

      // Dodaj koszt grafika (jeśli uwzględniony)
      if (includeGraphics) {
        const graphicDesignerRate = rates['grafik'];
        if (graphicDesignerRate) {
          let rate;
          switch (qualityLevel) {
            case 'modest':
              rate = graphicDesignerRate.min;
              break;
            case 'basic':
              rate = (graphicDesignerRate.min + graphicDesignerRate.max) / 2;
              break;
            case 'premium':
              rate = graphicDesignerRate.max;
              break;
            default:
              rate = (graphicDesignerRate.min + graphicDesignerRate.max) / 2;
          }
          const graphicsCost = rate;
          totalCost += graphicsCost;
          detailedCrew.push({ role: 'Grafik', daysNeeded: 1, cost: Math.ceil(graphicsCost) });
        }
      }
    }

    // Dodaj koszt sprzętu
    const equipmentCosts = {
      modest: 800,
      basic: 1500,
      premium: 4000
    };
    const equipmentCost = equipmentCosts[qualityLevel] * shootingDays;
    totalCost += equipmentCost;

    // Dodaj koszt color gradingu
    let colorGradingCost = 0;
    if (includeColorGrading) {
      colorGradingCost = 800 * finalDuration;
      totalCost += colorGradingCost;
    }

    // Dodaj koszt lokacji, jeśli potrzebna
    if (analysisResult.needsExternalLocation) {
      totalCost += analysisResult.estimatedLocationCost;
    }

    return {
      totalCost: Math.ceil(totalCost),
      detailedCrew,
      equipmentCost,
      colorGradingCost
    };
  }, []);

  // Kontynuacja w następnym oknie odpowiedzi...
  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
  
    if (!openaiRef.current) {
      setError('OpenAI nie jest zainicjalizowane');
      setIsLoading(false);
      return;
    }
  
    if (!projectDescription || !technicalRequirements || !shootingDays) {
      setError('Proszę wypełnić wszystkie wymagane pola.');
      setIsLoading(false);
      return;
    }
  
    const cacheKey = md5(projectDescription + technicalRequirements + additionalInfo + includeEditing + includeGraphics + includeColorGrading + estimatedFinalDuration + (crewCategories ? crewCategories.join(',') : '') + qualityLevel + shootingDays);
  
    if (cache.has(cacheKey)) {
      setAnalysis(cache.get(cacheKey));
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await openaiRef.current.chat.completions.create({
        model: "gpt-4",
        messages: [
          {
            role: "system",
            content: `Jesteś ekspertem w analizie projektów wideo i przygotowywaniu kosztorysów. Twoim zadaniem jest dokładne oszacowanie parametrów projektu na podstawie opisu, uwzględniając następujące wytyczne:

            1. Przygotuj minimalny racjonalny kosztorys.
            2. Dzień zdjęciowy trwa 12 godzin i ma 1 godzinę przerwy.
            3. Jeśli w opisie projektu jest uwzględnione nagranie wypowiedzi, rozmowy, lub wywiadu, zawsze uwzględnij stanowisko charakteryzatora.
            4. Jeśli projekt ma wymagania dotyczące drona lub zdjęć lotniczych, zawsze uwzględnij operatora drona.
            5. ${includeEditing ? 'Uwzględnij montaż w kosztorysie.' : 'Nie uwzględniaj montażu w kosztorysie.'}
            6. ${includeGraphics ? 'Uwzględnij grafika w kosztorysie (zawsze jako jeden dzień pracy).' : 'Nie uwzględniaj grafika w kosztorysie.'}
            7. Oceń, czy potrzebna jest wynajęta zewnętrznie lokacja.
            8. Poziom jakości projektu: ${qualityLevel}. Dostosuj skład ekipy i stawki odpowiednio.
            9. Maksymalna liczba dni zdjęciowych: ${shootingDays}. Nie przekraczaj tej liczby w wycenie.

            Kategorie ekipy:
            ${crewCategories.map(category => `- ${category.charAt(0).toUpperCase() + category.slice(1)}`).join('\n')}

            Podaj wyniki w następującym formacie JSON:
            {
              "shootingDays": liczba (minimum 1, maksimum ${shootingDays}),
              "crewSize": liczba,
              "recordingHours": liczba,
              "finalDuration": liczba,
              "suggestedCrew": [
                {
                  "role": "nazwa stanowiska",
                  "daysNeeded": liczba
                },
                ...
              ],
              "needsDrone": boolean,
              "needsMakeup": boolean,
              "needsExternalLocation": boolean,
              "estimatedLocationCost": liczba (jeśli needsExternalLocation jest true),
              "reasoning": "Szczegółowe uzasadnienie dla sugerowanej ekipy, czasu produkcji i kosztorysu"
            }
            
            Upewnij się, że Twoja odpowiedź jest poprawnym obiektem JSON.`
          },
          {
            role: "user",
            content: `Opis projektu: ${projectDescription}
            Wymagania techniczne: ${technicalRequirements}
            Dodatkowe informacje: ${additionalInfo}
            ${includeEditing ? 'Uwzględnij również montaż.' : 'Nie uwzględniaj montażu.'}
            ${includeGraphics ? 'Uwzględnij również grafika (jeden dzień pracy).' : 'Nie uwzględniaj grafika.'}
            ${estimatedFinalDuration ? `Szacowana długość końcowego materiału: ${estimatedFinalDuration} minut.` : ''}
            Maksymalna liczba dni zdjęciowych: ${shootingDays}`
          }
        ],
        temperature: 0.2,
        seed: 42
      });

      let analysisResult;
      try {
        analysisResult = JSON.parse(response.choices[0].message.content);
      } catch (parseError) {
        console.error("Błąd parsowania JSON:", parseError);
        console.log("Surowa odpowiedź:", response.choices[0].message.content);
        throw new Error("Nie udało się przetworzyć odpowiedzi. Spróbuj ponownie.");
      }

      console.log("Parsed analysis result:", analysisResult);

      const calculatedCosts = calculateCosts(
        analysisResult,
        rates,
        qualityLevel,
        includeGraphics,
        includeColorGrading,
        analysisResult.shootingDays,
        analysisResult.finalDuration
      );

      const result = {
        ...analysisResult,
        ...calculatedCosts,
        qualityLevel,
        needsExternalLocation: analysisResult.needsExternalLocation,
        estimatedLocationCost: analysisResult.needsExternalLocation ? analysisResult.estimatedLocationCost : 0
      };

      cache.set(cacheKey, result);
      setAnalysis(result);
    } catch (error) {
      console.error('Błąd podczas analizy:', error);
      setError('Wystąpił błąd podczas analizy. Spróbuj ponownie.');
    } finally {
      setIsLoading(false);
    }
  }, [projectDescription, technicalRequirements, additionalInfo, includeEditing, includeGraphics, includeColorGrading, estimatedFinalDuration, crewCategories, qualityLevel, shootingDays, rates, calculateCosts]);

  const isFormValid = projectDescription && technicalRequirements && shootingDays;

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Wycena Projektów Wideo</h1>
      {error && <div style={{ color: 'red', marginBottom: '20px' }}>{error}</div>}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="projectDescription" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
            Opis projektu:
          </label>
          <textarea
            id="projectDescription"
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
            placeholder="Opisz ogólne założenia projektu, cel, target, etc."
            rows="6"
            style={{
              width: '100%',
              padding: '15px',
              fontSize: '16px',
              lineHeight: '1.5',
              border: '2px solid #ccc'
            }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="technicalRequirements" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
            Wymagania techniczne:
          </label>
          <textarea
            id="technicalRequirements"
            value={technicalRequirements}
            onChange={(e) => setTechnicalRequirements(e.target.value)}
            placeholder="Opisz wymagania techniczne, sprzętowe, lokalizacyjne, etc."
            rows="6"
            style={{
              width: '100%',
              padding: '15px',
              fontSize: '16px',
              lineHeight: '1.5',
              border: '2px solid #ccc'
            }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="additionalInfo" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
            Dodatkowe informacje:
          </label>
          <textarea
            id="additionalInfo"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder="Dodatkowe informacje, uwagi, specjalne wymagania, etc."
            rows="6"
            style={{
              width: '100%',
              padding: '15px',
              fontSize: '16px',
              lineHeight: '1.5',
              border: '2px solid #ccc'
            }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label style={{ fontSize: '18px', marginRight: '10px' }}>
            <input
              type="checkbox"
              checked={includeEditing}
              onChange={(e) => setIncludeEditing(e.target.checked)}
              style={{ marginRight: '5px' }}
            />
            Uwzględnij montaż
          </label>
          <label style={{ fontSize: '18px', marginRight: '10px' }}>
            <input
              type="checkbox"
              checked={includeGraphics}
              onChange={(e) => setIncludeGraphics(e.target.checked)}
              style={{ marginRight: '5px' }}
            />
            Uwzględnij grafika
          </label>
          <label style={{ fontSize: '18px' }}>
            <input
              type="checkbox"
              checked={includeColorGrading}
              onChange={(e) => setIncludeColorGrading(e.target.checked)}
              style={{ marginRight: '5px' }}
            />
            Uwzględnij color grading
          </label>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="estimatedFinalDuration" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
            Szacowana długość końcowego materiału (w minutach):
          </label>
          <input
            type="number"
            id="estimatedFinalDuration"
            value={estimatedFinalDuration}
            onChange={(e) => setEstimatedFinalDuration(e.target.value)}
            placeholder="Np. 15"
            style={{ width: '100%', padding: '10px', fontSize: '16px', border: '2px solid #ccc' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="qualityLevel" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
            Jakość produkcji:
          </label>
          <select
            id="qualityLevel"
            value={qualityLevel}
            onChange={(e) => setQualityLevel(e.target.value)}
            style={{ width: '100%', padding: '10px', fontSize: '16px', border: '2px solid #ccc' }}
          >
            <option value="modest">Modest</option>
            <option value="basic">Basic</option>
            <option value="premium">Premium</option>
          </select>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="shootingDays" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
            Maksymalna liczba dni zdjęciowych:
          </label>
          <input
            type="number"
            id="shootingDays"
            value={shootingDays}
            onChange={(e) => setShootingDays(e.target.value)}
            placeholder="Np. 3"
            style={{ width: '100%', padding: '10px', fontSize: '16px', border: '2px solid #ccc' }}
          />
        </div>
        <button type="submit" className="submit-button" disabled={isLoading || !isFormValid}>
          {isLoading ? 'Analizowanie...' : 'Analizuj i Wycen'}
        </button>
      </form>
      {isLoading && <LoadingSpinner />}
      {analysis && (
        <div style={{ marginTop: '20px', border: '2px solid #000', padding: '20px' }}>
          <h2>Analiza projektu:</h2>
          <p>Szacowana liczba dni zdjęciowych: {analysis.shootingDays}</p>
          <p>Przybliżona wielkość ekipy: {analysis.crewSize} osób</p>
          <p>Czas nagrań: {analysis.recordingHours} godzin</p>
          <p>Szacowana długość końcowego materiału: {analysis.finalDuration} minut</p>
          <h3>Sugerowana ekipa:</h3>
          <ul>
            {analysis.detailedCrew.map((crewMember, index) => (
              <li key={index}>{crewMember.role}: {crewMember.daysNeeded} dni, {crewMember.cost} PLN</li>
            ))}
          </ul>
          <p>Jakość produkcji: {analysis.qualityLevel.charAt(0).toUpperCase() + analysis.qualityLevel.slice(1)}</p>
          <p>Koszt sprzętu: {analysis.equipmentCost} PLN</p>
          {includeColorGrading && (
            <p>Koszt color gradingu: {analysis.colorGradingCost} PLN</p>
          )}
          {analysis.needsExternalLocation && (
            <p>Szacunkowy koszt wynajmu lokacji: {analysis.estimatedLocationCost} PLN</p>
          )}
          <p>Szacunkowy całkowity koszt: {analysis.totalCost} PLN</p>
          <p>Uzasadnienie: {analysis.reasoning}</p>
        </div>
      )}
    </div>
  );
}

export default App;